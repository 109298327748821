import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'modern-tutorial-initial-screen-page',
  templateUrl: './tutorial-initial-screen-page.component.html',
  styleUrls: ['./tutorial-initial-screen-page.component.scss']
})
export class TutorialInitialScreenPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
