<div>
  <div class="m-16">
    <div class="container mx-auto" *ngIf="user">
      <div class="flex flex-col sm:flex-row gap-4">
        <div class="w-full sm:w-[325px]">
          <!-- Profile Card -->
          <div class="card-box-1">
            <div class="image overflow-hidden">
              <img class="h-auto w-full mx-auto" [src]="user.icon_link || './assets/img/anon.png'" alt="">
            </div>
            <h1 class="text-gray-900 font-bold text-xl leading-8 my-1">{{ user.displayname }}</h1>
            <h3 class="text-gray-600 font-lg text-semibold leading-6">@{{ user.username }}</h3>
            <p class="text-sm text-gray-500 hover:text-gray-600 leading-6">{{ user.bio }}</p>
            <ul
              class="bg-gray-100 text-gray-600 hover:text-gray-700 hover:shadow py-2 px-3 mt-3 divide-y rounded shadow-sm">
              <li class="flex items-center py-3">
                <span>Status</span>
                <span class="ml-auto"><span
                    class="bg-green-500 py-1 px-2 rounded text-white text-sm">Active</span></span>
              </li>
              <li class="flex items-center py-3">
                <span>Member since</span>
                <span class="ml-auto">{{ user.created_at | date }} ({{ user.created_at | timeAgo }})</span>
              </li>
            </ul>
          </div>
          <!-- End of profile card -->
        </div>

        <div class="grow">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>
