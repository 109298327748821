<div class="container mx-auto">
  <div class="background-white m-16">
    <div class="p-16 border border-gray-300 shadow-sm">
      <fieldset class="uk-fieldset">
        <p class="text-5xl mb-10">Password Reset</p>
        <p class="text-xl mb-5">
          Enter and submit your email to request a password reset request.
        </p>

        <div class="mb-7">
          <span class="label-info mb-3 block">
            Email
          </span>
          <input class="input-text-1" type="email" placeholder="Enter an Email" [(ngModel)]="emailInput" >
        </div>
      </fieldset>

      <button
        type="button"
        [ngClass]="{
          'btn-primary-1': !loading && !!emailInput,
          'btn-disabled-1': loading || !emailInput
        }"
        [disabled]="loading || !emailInput"
        (click)="onSubmitEmail()"
      >
        Submit
      </button>
    </div>
  </div>
</div>