<div class="container mx-auto">
  <div class="background-white m-3 sm:m-16">
    <section class="p-3 sm:p-16 border border-gray-300 shadow-sm">
      
      <p class="text-5xl mb-10">Verifying your identity with Stripe Identity</p>
      <p class="text-xl mb-5">
        Carry uses <a href="https://stripe.com/identity" target="_blank">Stripe Identity</a> for verifying all users of the platform. 
        Just like with all other services, Stripe Identity does not disclose any personal information to Carry or any other service without your permission. 
        To verify your identity, follow the steps below.
      </p>
      <p class="text-xl mb-5">
        NOTE: the User Interface has been modified since the app was first developed.
      </p>



      <p class="text-xl mb-5">
        Once logged in, you will start off at the "Home" screen.
      </p>
      <div class="flex flex-col sm:flex-row flex-wrap gap-3">
        <img class="w-full sm:w-96 polaroid mb-5" [src]="'https://static.carryapp.me/assets/previews/iphone/stripe-connect-onboarding-return-connected.png'">
      </div>


      <p class="text-xl mb-5">
        Go to the settings (bottom right icon).
      </p>
      <div class="flex flex-col sm:flex-row flex-wrap gap-3">
        <img class="w-full sm:w-96 polaroid mb-5" [src]="'https://static.carryapp.me/assets/previews/iphone/settings-main-screen-verified.png'">
      </div>

      <p class="text-xl mb-5">
        Go to Stripe Identity. From there, tap the "Verify Identity" button to begin the identity verification process. You will be brought to a page that has your session prepared for you to upload verification document(s).
      </p>
      <div class="flex flex-col sm:flex-row flex-wrap gap-3">
        <img class="w-full sm:w-96 polaroid mb-5" [src]="'https://static.carryapp.me/assets/previews/iphone/stripe-identity-verification-session-begin.png'">
      </div>

      <p class="text-xl mb-5">
        Click the "Submit Verification Documents" button.
      </p>
      <div class="flex flex-col sm:flex-row flex-wrap gap-3">
        <img class="w-full sm:w-96 polaroid mb-5" [src]="'https://static.carryapp.me/assets/previews/iphone/stripe-identity-verification-session-upload-page.png'">
      </div>

      <p class="text-xl mb-5">
        After completing the verification document uploads, you should be receiving a push notification indicating that your identity has finished processing and is verified.
      </p>
      <div class="flex flex-col sm:flex-row flex-wrap gap-3">
        <img class="w-full sm:w-96 polaroid mb-5" [src]="'https://static.carryapp.me/assets/previews/iphone/stripe-identity-verification-session-upload-modal.png'">
      </div>

      <p class="text-xl mb-5">
        Go back to the mobile app. In the Stripe Identity settings, you should see text indicating verification success.
      </p>
      <div class="flex flex-col sm:flex-row flex-wrap gap-3">
        <img class="w-full sm:w-96 polaroid mb-5" [src]="'https://static.carryapp.me/assets/previews/iphone/stripe-identity-verification-session-verified-submission.png'">
        <img class="w-full sm:w-96 polaroid mb-5" [src]="'https://static.carryapp.me/assets/previews/iphone/stripe-identity-verification-session-verified-message.png'">
        <img class="w-full sm:w-96 polaroid mb-5" [src]="'https://static.carryapp.me/assets/previews/iphone/stripe-identity-verification-session-completed.png'">
      </div>


    </section>
  </div>
</div>