<div class="container mx-auto">
  <div class="background-white m-3 sm:m-16">
    <section class="p-3 sm:p-16 border border-gray-300 shadow-sm">
      
      <div class="font-sans">
        <p class="text-5xl mb-10">About</p>
        
        <div>
          <!-- <p class="text-3xl mb-5">Introduction</p> -->
          
          <p class="text-3xl mb-2">What is Carry?</p>
          <p class="text-lg mb-3">
            Carry, Inc. (or simply <span class="font-bold">Carry</span>) is a shipping/transportation as a service platform, allowing users to become their own shipping/transportation provider similar to UPS, FedEx, etc. 
            A user can request an item to be transported from one location to another; another user of the platform can fulfill that request. Similar to <a href="https://doordash.com" target="_blank" class="font-bold">DoorDash</a> but instead of food, 
            its anything someone wants transported, and similar to <a href="https://airbnb.com" target="_blank" class="font-bold">Airbnb</a> where it's C2C (customer to customer) service marketplace. 
            It's like <a href="https://flex.amazon.com" target="_blank" class="font-bold">Amazon Flex</a> but instead of delivering for Amazon, your delivering for regular people in the community. 
            The vision is to have more shipping/transporting services and conveniences that works not only for B2C (business to customer) but also for B2B, C2C, and C2B.
          </p>

          <p class="text-3xl mb-2">Why Carry?</p>
          <p class="text-lg mb-2">
            Carry seeks to provide the speed and efficiency of moving items by leveraging available people in the community via the convenience of a platform, 
            readily accessible on your smartphone. Some of the core scenarios Carry want to address:
          </p>
          <ul>
            <li>You left something somewhere and you don't want to make a trip to get it and another trip to come back.</li>
            <li>You need something taken somewhere but don't have the time to do it.</li>
            <li>You ordered something online in the same city as you and don't want to wait for days via UPS or FedEx or even Amazon next day delivery.</li>
            <li>You ordered something online that's available for pickup same day but would like the option to have it brought to you or somewhere else.</li>
          </ul>
          <p class="text-lg mb-3">
            Essentially, Carry is a new community-based item/package shipping and transportation option. 
            The goal is to create another self-employment option as a transportation service provider, giving people another delivery option for a faster and more direct delivery of items.
          </p>

          <p class="text-lg mb-3">
            Right now, the platform is focused on marketing and determining if there is a need for this service. There is a fully working mobile app for people to download and use.
          </p>

          <p class="text-xl mt-5 mb-3">
            To see a video demo of how the app works, see the <a [routerLink]="['/demo']">Demo Video</a>.
          </p>
          <p class="text-xl mt-5 mb-3">
            For any questions/comments, please reach out via contact email found on the <a [routerLink]="['/contact']">Contact</a> page.
          </p>
        </div>
      </div>

    </section>
  </div>
</div>