<div class="container mx-auto">
  <div class="background-white m-3 sm:m-16">
    <section class="p-3 sm:p-16 border border-gray-300 shadow-sm">
      
      <div class="font-sans">
        <p class="text-5xl mb-10">Security & Protection</p>

      </div>

    </section>
  </div>
</div>