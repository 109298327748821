/** Enums */

export enum AlertTypes {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  DANGER = 'danger',
  SECONDARY = 'secondary',
  // PRIMARY = 'primary',
  // LIGHT = 'light',
  // DARK = 'dark',
}

export const AlertTypeTailwindColor = Object.freeze({
  [AlertTypes.SUCCESS]: `green`,
  [AlertTypes.INFO]: `blue`,
  [AlertTypes.WARNING]: `yellow`,
  [AlertTypes.DANGER]: `red`,
  [AlertTypes.SECONDARY]: `slate`,
  // [AlertTypes.PRIMARY]: ``,
  // [AlertTypes.LIGHT]: ``,
  // [AlertTypes.DARK]: ``,
});

export const AlertDivClass = Object.freeze({
  [AlertTypes.SUCCESS]: `bg-green-100 border-green-400 text-green-700`,
  [AlertTypes.INFO]: `bg-blue-100 border-blue-400 text-blue-700`,
  [AlertTypes.WARNING]: `bg-yellow-100 border-yellow-400 text-yellow-700`,
  [AlertTypes.DANGER]: `bg-red-100 border-red-400 text-red-700`,
  [AlertTypes.SECONDARY]: `bg-slate-100 border-slate-400 text-slate-700`,
});

export const AlertTextClass = Object.freeze({
  [AlertTypes.SUCCESS]: `text-green-500`,
  [AlertTypes.INFO]: `text-blue-500`,
  [AlertTypes.WARNING]: `text-yellow-500`,
  [AlertTypes.DANGER]: `text-red-500`,
  [AlertTypes.SECONDARY]: `text-slate-500`,
});

export enum StoreKeys {
  USER_FIELDS = 'USER_FIELDS',
  LOCATION_PREFERENCES = 'LOCATION_PREFERENCES',
}

export enum USER_RECORDS {
  // common
  FIELDS = 'fields',
  CONNECTIONS = 'connections',
  CONNECTION_REQUESTS = 'connection-requests',
  RESOURCES = 'resources',
  RESOURCE_INTERESTS = 'resource-interests',
  NOTIFICATIONS = 'notifications',
  FOLLOWERS = 'followers',
  FOLLOWINGS = 'followings',
  
  //hotspot
  POSTS = 'posts',
  CLIQUES = 'cliques',
  CLIQUE_INTERESTS = 'clique-interests',
  CLIQUE_MEMBERSHIPS = 'clique-memberships',
  CLIQUE_MEMBERSHIP_REQUESTS = 'member-requests',
  
  
  // deliverme
  DELIVERIES = 'deliveries',
  DELIVERINGS = 'deliverings',
  
  //travellrs
  MARKERS = 'markers',
  
  // world news



  // my favors
  FAVORS = 'favors',
  FAVOR_HELPINGS_ACTIVE = 'favor-helpings-active',
  FAVOR_HELPINGS_PAST = 'favor-helpings-past',


  
  // chef city
  RECIPES = 'recipes',
}

export enum MODERN_APP_NAMES {
  COMMON = 'COMMON',
  CHEFCITY = 'CHEFCITY',
  DELIVERME = 'DELIVERME',
  MYFAVORS = 'MYFAVORS',
  CONTENDER = 'CONTENDER',
  TRAVELLRS = 'TRAVELLRS',
  HOTSPOT = 'HOTSPOT',
  WORLDNEWS = 'WORLDNEWS',
  CARMASTER = 'CARMASTER',
}

export enum MODERN_APPS {
  COMMON = 'common',
  HOTSPOT = 'hotspot',
  DELIVERME = 'deliverme',
  TRAVELLRS = 'travellrs',
  WORLDNEWS = 'worldnews',
  MYFAVORS = 'myfavors',
  CARMASTER = 'carmaster',
}

export enum REACTIONS {
  LIKE = 1,
  DISLIKE,
  LOVE,
  CLAP,
  IDEA,
  CONFUSED,
  EXCITED,
  CARE,
  LAUGH,
  WOW,
  SAD,
  UPSET,
  FIRE,
  ONE_HUNDRED,
}
export enum REACTION_TYPES {
  LIKE = 'LIKE',
  DISLIKE = 'DISLIKE',
  LOVE = 'LOVE',
  CLAP = 'CLAP',
  IDEA = 'IDEA',
  CONFUSED = 'CONFUSED',
  EXCITED = 'EXCITED',
  CARE = 'CARE',
  LAUGH = 'LAUGH',
  WOW = 'WOW',
  SAD = 'SAD',
  UPSET = 'UPSET',
  FIRE = 'FIRE',
  ONE_HUNDRED = 'ONE_HUNDRED',
}

export enum PREMIUM_SUBSCRIPTIONS {
  BACKGROUND_MARKETING = 'BACKGROUND_MARKETING',
}

export enum NOTIFICATION_TARGET_TYPES {
  RESOURCE = 'RESOURCE',
  CLIQUE = 'CLIQUE',
  MESSAGING = 'MESSAGING',
  MESSAGE = 'MESSAGE',
  CONVERSATION = 'CONVERSATION',
}

export enum SUBSCRIPTION_TARGET_ACTIONS {
  
}

export enum SUBSCRIPTION_TARGET_ACTIONS_INFO {
  
}

export enum USER_CARD_LAYOUTS {
  LONG,
  BOX,
}

export enum SUBSCRIPTION_TARGET_FREQ {
  INSTANT = 'INSTANT',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

export enum COMMON_EVENT_TYPES {
  // hotspot
  NEW_CLIQUE = 'NEW_CLIQUE',
  CLIQUE_MEMBER_REQUEST = 'CLIQUE_MEMBER_REQUEST',
  CLIQUE_MEMBER_CANCEL = 'CLIQUE_MEMBER_CANCEL',
  CLIQUE_MEMBER_ACCEPT = 'CLIQUE_MEMBER_ACCEPT',
  CLIQUE_MEMBER_DECLINE = 'CLIQUE_MEMBER_DECLINE',
  CLIQUE_MEMBER_ADDED = 'CLIQUE_MEMBER_ADDED',
  CLIQUE_MEMBER_REMOVED = 'CLIQUE_MEMBER_REMOVED',
  CLIQUE_MEMBER_LEFT = 'CLIQUE_MEMBER_LEFT',
  NEW_CLIQUE_INTEREST = 'NEW_CLIQUE_INTEREST',
  CLIQUE_UNINTEREST = 'CLIQUE_UNINTEREST',
  NEW_RESOURCE_INTEREST = 'NEW_RESOURCE_INTEREST',
  RESOURCE_UNINTEREST = 'RESOURCE_UNINTEREST',
  CLIQUE_UPDATED = 'CLIQUE_UPDATED',
  CLIQUE_DELETED = 'CLIQUE_DELETED',
  CONNECTION_REQUEST = 'CONNECTION_REQUEST',
  CONNECTION_CANCEL = 'CONNECTION_CANCEL',
  CONNECTION_ACCEPT = 'CONNECTION_ACCEPT',
  CONNECTION_DECLINE = 'CONNECTION_DECLINE',
  CONNECTION_BROKEN = 'CONNECTION_BROKEN',

  // socket actions
  SOCKET_TRACK = 'SOCKET_TRACK',
  SOCKET_TO_USER_EVENT = 'SOCKET_TO_USER_EVENT',
  SOCKET_JOIN_ROOM = 'SOCKET_JOIN_ROOM',
  SOCKET_LEAVE_ROOM = 'SOCKET_LEAVE_ROOM',

  // events
  NEW_MESSAGE = 'NEW_MESSAGE',
  NEW_MESSAGING = 'NEW_MESSAGING',
  MESSAGING_EVENTS_SUBSCRIBED = 'MESSAGING_EVENTS_SUBSCRIBED',
  MESSAGING_EVENTS_UNSUBSCRIBED = 'MESSAGING_EVENTS_UNSUBSCRIBED',
  NEW_FOLLOWER = 'NEW_FOLLOWER',
  NEW_UNFOLLOWER = 'NEW_UNFOLLOWER',
  NEW_CONVERSATION = 'NEW_CONVERSATION',
  NEW_CONVERSATION_MESSAGE = 'NEW_CONVERSATION_MESSAGE',
  CONVERSATION_MEMBER_ADDED = 'CONVERSATION_MEMBER_ADDED',
  CONVERSATION_MEMBER_REMOVED = 'CONVERSATION_MEMBER_REMOVED',
  CONVERSATION_MEMBER_LEFT = 'CONVERSATION_MEMBER_LEFT',
  CONVERSATION_EVENTS_SUBSCRIBED = 'CONVERSATION_EVENTS_SUBSCRIBED',
  CONVERSATION_EVENTS_UNSUBSCRIBED = 'CONVERSATION_EVENTS_UNSUBSCRIBED',
  TO_MESSAGING_ROOM = 'TO_MESSAGING_ROOM',
  JOIN_TO_MESSAGING_ROOM = 'JOIN_TO_MESSAGING_ROOM',
  LEAVE_TO_MESSAGING_ROOM = 'LEAVE_TO_MESSAGING_ROOM',
  MESSAGE_TYPING = 'MESSAGE_TYPING',
  MESSAGE_TYPING_STOPPED = 'MESSAGE_TYPING_STOPPED',
  CONVERSATION_MESSAGE_TYPING = 'CONVERSATION_MESSAGE_TYPING',
  CONVERSATION_MESSAGE_TYPING_STOPPED = 'CONVERSATION_MESSAGE_TYPING_STOPPED',
  CONVERSATION_UPDATED = 'CONVERSATION_UPDATED',
  CONVERSATION_DELETED = 'CONVERSATION_DELETED',
}

export enum CRON_JOB_TYPES {
  UNSUSCRIBE_PREMIUM = 'UNSUSCRIBE_PREMIUM',
}

export enum USER_TYPES {
  ENTREPRENEUR = 'ENTREPRENEUR',
  INVESTOR = 'INVESTOR',
  PARTNER = 'PARTNER',
}

export enum RESOURCE_TYPES {
  BOOK = 'BOOK',
  VIDEO = 'VIDEO',
  EVENT = 'EVENT',
  ARTICLE = 'ARTICLE',
  WEBSITE = 'WEBSITE',
  PERSON = 'PERSON',
  OTHER = 'OTHER',
}

export enum CommentModel {
  POST = 'POST',
  LINK = 'LINK',
  RECIPE = 'RECIPE',
}

export enum CommentParent {
  POST = 'post',
  RECIPE = 'recipe',
}

export enum ReplyModel {
  POST = 'POST',
  LINK = 'LINK',
  RECIPE = 'RECIPE',
}

export const COMMON_STATUSES =  Object.freeze({
  PENDING: `PENDING`,
  CANCELED: `CANCELED`,
  ACCEPTED: `ACCEPTED`,
  DECLINED: `DECLINED`,
  OPEN: `OPEN`,
  RESCINDED: `RESCINDED`,
});