<div class="container mx-auto">
  <div class="background-white m-3 sm:m-16">
    <section class="p-3 sm:p-16 border border-gray-300 shadow-sm">
      
      <p class="text-5xl mb-10">Login</p>
      <p class="text-xl mb-5">
        After creating an account, you will now be able to log out and log in.
      </p>
      <p class="text-xl mb-5">
        From the initial screen, tap the "Log In" button.
      </p>
      <img class="w-full sm:w-96 polaroid mb-5" [src]="'https://static.carryapp.me/assets/previews/iphone/landing-screen.png'">

      <p class="text-xl mb-5">
        The login form requires basic email (or username, which you can change in your settings) and password.
      </p>
      <img class="w-full sm:w-96 polaroid mb-5" [src]="'https://static.carryapp.me/assets/previews/iphone/login-screen.png'">

      <p class="text-xl mb-5">
        After successfully logging in, you will be brought to the main home page. 
      </p>
      <img class="w-full sm:w-96 polaroid mb-5" [src]="'https://static.carryapp.me/assets/previews/iphone/home-screen-unverified.png'">

    </section>
  </div>
</div>