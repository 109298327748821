<div class="mx-auto container py-5">
  <div class="card-box-1" *ngIf="!delivery" class="margin-bottom-20">
    <div class="uk-card-body">
      <p>No Delivery Found</p>
    </div>
  </div>

  <div *ngIf="delivery" class="margin-bottom-20">
    <deliverme-delivery-card
      [delivery]="delivery"
      [you]="you"
      (deliveryDelete)="onDeleteDelivery(delivery)"
      (deliveryCompleted)="onCurrentDeliveryCompleted()"
      (deliveryReturned)="onCurrentDeliveryReturned()"
    ></deliverme-delivery-card>
  </div>
</div>