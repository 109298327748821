<div class="container mx-auto">
  <div class="background-white m-3 sm:m-16">
    <section class="p-3 sm:p-16 border border-gray-300 shadow-sm">
      
      <div class="font-sans">
        <p class="text-5xl mb-10">Terms & Agreements</p>

        <ul class="list-disc text-lg">
          <li>All users of the app must have their identities verified to become a service provider.</li>
          <li>No illegal substances/objects should be listed as items to transport. We urge users to have honesty and integrity while using the platform.</li>
          <li>The platform is NOT liable for violent crimes committed via the platform however help will be offered in the event a customer or service provider engages in misconduct.</li>
          <li>
            Stealing will not be tolerated from service providers. Any/every information available to the platform will be used to seek legal action against any service provider committing any acts of stealing.
            <ul class="list-disc">
              <li>For customers that create a delivery listing, a reimbursement amount is required to determine compensation in any event of misconduct from a service provider.</li>
              <li>When creating a delivery listing, there is an option to add insurance to the listing, meaning the platform will immediately pay out the reimbursement amount.</li>
              <li>For customers that have an active subscription to the platform, all delivery listings will be insured.</li>
            </ul>
          </li>
          <li>All carriers (a user who is fulfilling a delivery) should communicate with the delivery owner and complete the run in a timely manner.</li>
          <li>The platform offers a membership subscription for $3.99 USD/month for waived application fees and added insurance to delivery listings.</li>
          <!-- <li></li> -->
        </ul>

        <p class="text-2xl">The platform reserves/exercises the right to make changes to the terms and agreements.</p>
      </div>

    </section>
  </div>
</div>