<div id="alerts-list-container">
  <!-- <div
    *ngFor="let currentAlert of alertsList; index as i"
    class="alert alert-{{ currentAlert.type }}"
    role="alert"
  >
    <span>{{ currentAlert.message }}</span>
    <button type="button" class="close" aria-label="Close" (click)="closeAlert(currentAlert)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> -->

  <!-- <div
    *ngIf="alertsList.length"
    class=""
    role="alert"
    uk-alert
  >
    <a class="uk-alert-close" (click)="closeAlert()"></a>
    <p>{{ alertsList[0]?.message }} {{ alertsList.length > 1 ? '(' + alertsList.length + ')' : '' }}</p>
  </div> -->

  

  <section>
    <div
      *ngIf="alertsList.length" 
      class="border px-4 py-3 rounded relative flex flex-row gap-5"
      [ngClass]="useClassBox"
      role="alert"
    >
      <div class="grow">
        <strong class="font-bold mr-3">{{ alertsList[0].message }}</strong>
        <span class="block sm:inline">{{ alertsList.length > 1 ? '(' + alertsList.length + ')' : '' }}</span>
      </div>
      
      <div class="w-6">
        <span class="absolute top-0 bottom-0 right-0 px-4 py-3" (click)="closeAlert()">
          <svg [ngClass]="useClassText" class="fill-current h-6 w-6" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <title>Close</title>
            <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/>
          </svg>
        </span>
      </div>
    </div>
  </section>
</div>