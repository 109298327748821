<div class="container mx-auto">
  <div class="background-white m-16">
    <section class="p-16 border border-gray-300 shadow-sm">
      
      <div class="font-sans">
        <p class="text-5xl mb-10">Frequently Asked Questions</p>

        <ul class="list-disc">
          <li>
            <p class="text-2xl font-bold mb-2"></p>
            <p class="text-lg"></p>
          </li>


        </ul>
      </div>

    </section>
  </div>
</div>