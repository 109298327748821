<div class="container mx-auto">
  <div class="background-white m-3 sm:m-16">
    <section class="p-3 sm:p-16 border border-gray-300 shadow-sm">
      
      <p class="text-5xl mb-10">Sign Up</p>
      <p class="text-xl mb-5">
        From the initial screen, tap the "Sign Up" button.
      </p>
      <img class="w-full sm:w-96 polaroid mb-5" [src]="'https://static.carryapp.me/assets/previews/iphone/landing-screen.png'">

      <p class="text-xl mb-5">
        The sign up form consists of a few basic fields: name, email and password. The name you enter must be your legal name, it will be used for identity verification later. Fields with the asterisk (*) are required.
      </p>
      <div class="flex flex-col sm:flex-row flex-wrap gap-3">
        <img class="w-full sm:w-96 polaroid mb-5" [src]="'https://static.carryapp.me/assets/previews/iphone/signup-screen.png'">
        <img class="w-full sm:w-96 polaroid mb-5" [src]="'https://static.carryapp.me/assets/previews/iphone/signup-screen-example.png'">
      </div>

      <p class="text-xl mb-5">
        After successfully signing up, you will be brought to the main home page. The app will ask for permissions to send push notifications. Please accept the request in order to receive real time notifications on events that happen on the platform.
      </p>
      <img class="w-full sm:w-96 polaroid mb-5" [src]="'https://static.carryapp.me/assets/previews/iphone/signup-successful.png'">

    </section>
  </div>
</div>