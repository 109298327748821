<div class="container mx-auto">
  <div class="background-white m-16">
    <div class="p-16 border border-gray-300 shadow-sm">
      <fieldset class="uk-fieldset">
        <img src="https://static.carryapp.me/assets/img/spinner-loader-1.gif" alt="Loading icon" *ngIf="loading" />

        <p class="text-xl mb-5">
          {{ message }}
        </p>
      </fieldset>
    </div>
  </div>
</div>