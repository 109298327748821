<div class="container mx-auto">
  <div class="background-white m-3 sm:m-16">
    <section class="p-3 sm:p-16 border border-gray-300 shadow-sm">
      
      <div class="font-sans">
        <p class="text-5xl mb-10">Stripe Identity Upload</p>

        <!-- <p *ngIf="!!link">{{ link }}</p> -->

        <div *ngIf="!!verification_session_client_secret && !!stripe_pk && !verified">
          <p class="text-xl mb-5">
            Upload documents to verify your identity.
          </p>
          
          <button
            
            id="verify-button"
            type="button"
            class="btn-primary-1"
            [disabled]="loading"
            (click)="handleVerification()"
          >
            Submit Verification Documents
          </button>
        </div>

        <p *ngIf="verified" class="text-3xl">
          Verified! You should have gotten a mobile push notification. You may now close this web page.
        </p>
      </div>

    </section>
  </div>
</div>