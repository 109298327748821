<div class="container mx-auto">
  <div class="background-white m-3 sm:m-16">
    <section class="p-3 sm:p-16 border border-gray-300 shadow-sm">
      
      <div class="font-sans">
        <p class="text-5xl mb-10">Privacy Policy</p>

        <ul class="list-disc text-lg">
          <li>The platform uses <a class="font-bold" href="https://stripe.com" target="_blank">Stripe</a> to process and manage all finance and payment related activities; the platform (Carry) does not keep or maintain any personal finance information such as bank accounts, credit/debit cards, etc.</li>
          <li>The platform does/will not sell any user data; all information within the app exists only within the app solefy for functionality. Any changes will be communicated to users of the platform prior.</li>
          <li>Users will only see information pertaining to their service/customer related functions within the app.</li>
          <li>Users will/may not share or disclose personal information about deliveries they provide. This includes but is not limited to: addresses, names, etc.</li>
          <li>This app is intended for people age 18 or older.</li>
          <li>Stripe collects identity information to verify service providers; that information is NOT shared with the platform; Stripe will merely indicate if a service provider's verification/identity is valid or not.</li>
          <li>
            For transparency, the logic and application for the business core IP (intellectual property) will be hosted on GitHub for public review and feedback:
            <ul class="list-disc">
              <li>Backend Code: <a href="https://github.com/ryanwaite28/carry-backend-express" target="_blank">https://github.com/ryanwaite28/carry-backend-express</a></li>
              <li>Frontend Code: <a href="https://github.com/ryanwaite28/modern-client-new" target="_blank">https://github.com/ryanwaite28/modern-client-new</a></li>
            </ul>
          </li>
          <li>Users can request that their account and all data pertaining to it be deleted. A backup of their data can be requested as well. Send an email to <a href="mailto:business@carryapp.me">business@carryapp.me</a> for backup/deletion requests.</li>
        </ul>
      </div>

    </section>
  </div>
</div>