<div>

  <div class="flex flex-row container mx-auto bg-white">

    <section class="hidden sm:block w-full sm:w-[300px] p-8">
      <p class="text-2xl mb-3">Releases</p>
      <p>Click a release notes links to go to that page for more details.</p>
    </section>

    <section class="grow min-h-[80vh] border-l border-gray-300 p-8">
      <router-outlet></router-outlet>
    </section>

  </div>

</div>