import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'modern-app-demo-video-page',
  templateUrl: './app-demo-video-page.component.html',
  styleUrls: ['./app-demo-video-page.component.scss']
})
export class AppDemoVideoPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
