<div class="container mx-auto">
  <div class="background-white m-3 sm:m-16">
    <section class="p-3 sm:p-16 border border-gray-300 shadow-sm">
      
      <p class="text-5xl mb-10">Initial Screen</p>
      <p class="text-xl mb-5">
        Once you have the app downloaded, opening it will take you to the initial screen:
      </p>
      <img class="w-full sm:w-96 polaroid mb-5" [src]="'https://static.carryapp.me/assets/previews/iphone/landing-screen.png'">

      <p class="text-xl mb-5">
        From the initial app screen, there are buttons to start the signup process and to login. The Website link will take you to the main website for the app where other information can be found (mainly located at the bottom of the page, in the footer section).
      </p>

    </section>
  </div>
</div>