<div class="container mx-auto">
  <div class="background-white m-3 sm:m-16">
    <section class="p-3 sm:p-16 border border-gray-300 shadow-sm">
      
      <div class="font-sans">
        <p class="text-5xl mb-10">Technical Details</p>

        <p class="text-lg mb-5">For transparency to the public, the application logic are found in these 3 GitHub repo</p>
        <ul class="list-disc text-lg mb-5">
          <li>Back End: <a href="https://github.com/ryanwaite28/carry-backend-express">https://github.com/ryanwaite28/carry-backend-express</a></li>
          <li>Front End: <a href="https://github.com/ryanwaite28/modern-client-new/tree/master/projects/modern">https://github.com/ryanwaite28/modern-client-new/tree/master/projects/modern</a></li>
          <li>Mobile App: <a href="https://github.com/ryanwaite28/carry-mobile-expo-go-app">https://github.com/ryanwaite28/carry-mobile-expo-go-app</a></li>
        </ul>

        
      </div>

    </section>
  </div>
</div>