<div>
  <div *ngIf="!deliveries.length" class="card-box-1">
    <div class="uk-card-body">
      <p class="text-3xl mb-3">None</p>
    </div>
  </div>

  <div *ngFor="let delivery of deliveries" class="margin-bottom-20">
    <deliverme-delivery-card
      [delivery]="delivery"
      [you]="you"
      (deliveryDelete)="onDeleteDelivery(delivery)"
    ></deliverme-delivery-card>
  </div>

  <button
    *ngIf="!end_reached"
    type="submit"
    class="btn-primary-1"
    [disabled]="loading"
    (click)="getDeliveries()"
  >
    Load More
  </button>
</div>