import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'modern-tutorial-setting-up-stripe-connect-page',
  templateUrl: './tutorial-setting-up-stripe-connect-page.component.html',
  styleUrls: ['./tutorial-setting-up-stripe-connect-page.component.scss']
})
export class TutorialSettingUpStripeConnectPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
