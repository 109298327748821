<div class="container mx-auto">
  <div class="background-white m-3 sm:m-16">
    <section class="p-3 sm:p-16 border border-gray-300 shadow-sm">
      
      <div class="font-sans">
        <p class="text-5xl mb-10">Stripe Identity verification return page.</p>

        <!-- <p *ngIf="!!link">{{ link }}</p> -->

        <p class="text-xl mb-5">
          If verification was successful, you will be redirected to the app.
        </p>
        <div class="text-xl mb-5" *ngIf="isRedirecting">
          <p>Successfully completed Stripe Identity Verification! Redirecting to app...</p>
          <img src="https://static.carryapp.me/assets/img/spinner-loader-1.gif" alt="Loading icon" class="w-8 my-3" />
        </div>
      </div>

    </section>
  </div>
</div>