<div class="container mx-auto">
  <div class="background-white m-3 sm:m-16">
    <section class="p-3 sm:p-16 border border-gray-300 shadow-sm">
      
      <div class="font-sans">
        <p class="text-5xl mb-10">Help</p>

        <p class="text-xl mb-5">
          For any immediate help needed regarding platform services/functionality or all other concerns, please reach out via information found on the <a routerLink="['/contact']">contact</a> page.
        </p>
      </div>

    </section>
  </div>
</div>