import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'modern-tutorial-stripe-customer-account-page',
  templateUrl: './tutorial-stripe-customer-account-page.component.html',
  styleUrls: ['./tutorial-stripe-customer-account-page.component.scss']
})
export class TutorialStripeCustomerAccountPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
