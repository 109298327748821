<!-- <mat-toolbar>
  <span>
    <a [routerLink]="['/']">Modern</a>
  </span>

  <span class="mat-toolbar-spacer"></span>

  <ng-container [ngSwitch]="!!you">
    <ng-container *ngSwitchCase="false">
      <button mat-button>
        <span>
          <a [routerLink]="['/', 'signin']">Sign In</a>
        </span>
      </button>
      <button mat-button>
        <span>
          <a [routerLink]="['/', 'signup']">Sign Up</a>
        </span>
      </button>
    </ng-container>

    <ng-container *ngSwitchCase="true">
      <button mat-button>
        <span>
          Sign Out
        </span>
      </button>
      <button mat-button>
        <span>
          <a [routerLink]="['/']">Home</a>
        </span>
      </button>
    </ng-container>
  </ng-container>
</mat-toolbar> -->

<div>
  <nav class="flex items-center justify-between flex-wrap bg-primary p-6">
    <div class="flex items-center flex-shrink-0 text-white mr-6">
      <span class="font-semibold text-xl tracking-tight text-[color:white]">
        <a [routerLink]="['/']">Carry</a>
      </span>
    </div>
    <div class="block lg:hidden">
      <button (click)="showMobileNav = !showMobileNav" class="flex items-center px-3 py-2 border rounded text-teal-200 border-teal-400 text-[color:white]">
        <svg class="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
      </button>
    </div>

    <div class="hidden lg:relative flex-grow lg:flex lg:items-center lg:w-auto text-[color:white]">
      <div class="text-sm lg:flex-grow">
        <a *ngFor="let link of links" [routerLink]="link.href" routerLinkActive="active-link" class="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4">
          {{ link.text }}
        </a>
      </div>
    </div>

    <div class="w-full block flex-grow lg:hidden text-[color:white]" *ngIf="showMobileNav">
      <div class="text-sm lg:flex-grow">
        <a *ngFor="let link of links" [routerLink]="link.href" routerLinkActive="active-link" class="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4">
          {{ link.text }}
        </a>
      </div>
    </div>
  </nav>
</div>