<div class="container mx-auto">
  <div class="background-white m-3 sm:m-16">
    <section class="p-3 sm:p-16 border border-gray-300 shadow-sm">
      
      <div class="font-sans">
        <p class="text-5xl mb-10">Contact</p>

        <p class="text-xl mb-5">
          For all questions, comments, concerns and etc, please use any of the contact information below.
        </p>

        <ul class="list-disc text-lg">
          <li>Owner: <a target="_blank" href="https://www.linkedin.com/in/ryanwaite28">https://www.linkedin.com/in/ryanwaite28</a></li>
          <li>Email: <a href="mailto:business@carryapp.me">business@carryapp.me</a></li>
          <li>Phone: <a href="tel:2404296728">+1 (240) 429-6728</a></li>
        </ul>
      </div>

    </section>
  </div>
</div>