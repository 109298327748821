import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'modern-tutorial-view-stripe-connected-account-profile-page',
  templateUrl: './tutorial-view-stripe-connected-account-profile-page.component.html',
  styleUrls: ['./tutorial-view-stripe-connected-account-profile-page.component.scss']
})
export class TutorialViewStripeConnectedAccountProfilePageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
