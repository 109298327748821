<div>
  <div class="m-16">
    <div class="container mx-auto">
      <div class="card-box-1">
        <p class="text-5xl mb-7">
          {{ message || results?.message }}
        </p>
      </div>
    </div>
  </div>
</div>
