<div>
  <div class="container mx-auto">
    <div class="background-white m-3 sm:m-16">
      <section class="p-3 sm:p-16 border border-gray-300 shadow-sm">
        
        <p class="text-5xl mb-10">Background</p>
        <p class="text-xl mb-5">
          Read this page to learn about the idea and motivation for Carry and the events that follow.
        </p>
  
      </section>
    </div>
  </div>

  <!--  -->

  <div class="container mx-auto">
    <div class="background-white m-3 sm:m-16">
      <section class="p-3 sm:p-16 border border-gray-300 shadow-sm">
        
        <!-- Career -->
        <p class="text-4xl mb-10">Career</p>
        <p class="text-lg mb-5">
          I started my career back in 2015 during my time as a student/intern at <a class="font-bold" href="https://yearup.org" target="_blank">YearUp</a>. 
          A teacher's assistant selected a few of the students (me included) to take classes with <a class="font-bold" href="https://udacity.com" target="_blank">Udacity</a> (YearUp had a partnership with Udacity at the time, by coincidence). 
          I was enrolled in their <a class="font-bold" target="_blank" href="https://www.udacity.com/course/front-end-web-developer-nanodegree--nd0011">Front End Developer Nanodegree</a>; 
          that was my first exposure into the world of programming.
        </p>
        <p class="text-lg mb-5">
          At first, I wasn't sure if programming was the right career for me because of complicated it seemed at first. 
          However, that uncertainty was very short-lived after I realized I was hooked on the mystery-solving aspect of it; 
          I never wanted to and just couldn't walk away when I couldn't get something to work, and the pure excitement and joy in solving something after trying for hours/days felt unparalleled. 
          After researching more about what programming is and what programmers/software engineers/computer scientists do (and honestly how much the career pays), 
          I knew for a fact this is what I wanted my career to be; there was no second-guessing or turning back.
        </p>
        <p class="text-lg mb-5">
          I interned at <a class="font-bold" target="_blank" href="https://nasa.org">NASA</a> as part of the YearUp program and finished both the Front End Developer Nanodegree and the YearUp program in January 2016. 
          It turned out, I was the only student to finish the Nanodegree out of all those selected. The staff at Udacity was impressed and decided to invest in my career and education over the next few years; 
          I received many follow up scholarship opportunities to take more of Udacity's Nanodegrees. After finishing the YearUp program, I landed my first job as a web content developer for DOL (U.S Department of Labor) OSHA. 
          I've been working in the field as a software engineer ever since.
        </p>
        <!-- <p class="text-lg mb-5">
          I've completed the following:
        </p>
        <div class="flex flex-col sm:flex-row flex-wrap gap-3 justify-center">
          <img class="w-full sm:w-1/3 polaroid mb-5" src="https://s3-us-west-2.amazonaws.com/udacity-printer/production/certificates/abc4bbb0-fb91-4bd3-8403-ba85953239fc.svg" />
          <img class="w-full sm:w-1/3 polaroid mb-5" src="https://s3-us-west-2.amazonaws.com/udacity-printer/production/certificates/599eb187-2448-4f35-ba7f-81728b65f8ef.svg" />
          <img class="w-full sm:w-1/3 polaroid mb-5" src="https://s3-us-west-2.amazonaws.com/udacity-printer/production/certificates/18d92999-f94f-45c5-8115-18ccf26abd32.svg" />
          <img class="w-full sm:w-1/3 polaroid mb-5" src="https://s3-us-west-2.amazonaws.com/udacity-printer/production/certificates/fa1affe4-a98f-4c6a-9c44-17b386b5fbae.svg" />
          <img class="w-full sm:w-1/3 polaroid mb-5" src="https://s3-us-west-2.amazonaws.com/udacity-printer/production/certificates/6f666280-e182-4400-8b9b-ea2072a6d5ec.svg" />
          <img class="w-full sm:w-1/3 polaroid mb-5" src="https://s3-us-west-2.amazonaws.com/udacity-printer/production/certificates/e46c4644-97d3-4962-886d-afb21a621453.svg" />
          <img class="w-full sm:w-1/3 polaroid mb-5" src="https://s3-us-west-2.amazonaws.com/udacity-printer/production/certificates/e6e69a7f-3ba7-4e03-a3be-6997c7dbc083.svg" />
        </div> -->


        <!-- Education -->
        <p class="text-4xl my-10">Education</p>
        <p class="text-lg mb-5">
          I graduated from <a class="font-bold" target="_blank" href="https://www2.montgomeryschoolsmd.org/schools/kennedyhs/">JFK High School</a> in June 2014 and although I did get accepted into VSU (Virginia State University), I decided not to go; financially, it was purely nonsensical, even with student loans. 
          I didn't know what to do, what I wanted or think I should do, anything. 
        </p>
        <p class="text-lg mb-5">
          Luckily, a family friend reached out and suggested the YearUp program because he completed it himself; that is how I learned of it. 
          At first, I was skeptical because the idea of some opportunity being free isn't common. 
          But after reading plenty of YearUp's success stories and testimonials and learning of their status of a Non-Profit, I believed in it. 
          It took a year to <a class="font-bold" target="_blank" href="https://ryanwaite28.github.io/cover-letter/">finish</a> (as the name implies). 
        </p>
        <p class="text-lg mb-5">
          Thankfully for YearUp's alumni resources, they provided a fully free scholarship for an associate's in business administration/management at <a class="font-bold" target="_blank" href="https://egcc.edu/">EGCC</a> (Eastern Gateway Community College). 
          I started in January 2019 and <a class="font-bold" target="_blank" href="https://media.licdn.com/dms/image/C4E2DAQFPMvEwRpxNTQ/profile-treasury-document-images_800/1/1598155470229?e=1686182400&v=beta&t=IsqoixDJGPq0uooc7YYLJ3GfLrCRZJI7ETCAGGNjeVs">finished</a> in May 2020. 
          In the same month of May 2020, I started my Computer Science bachelors at <a class="font-bold" target="_blank" href="https://umgc.edu">UMGC</a>. I <a class="font-bold" target="_blank" href="https://www.parchment.com/u/award/30d516661127b7ee9f7b160cbe7a668a">graduated</a> in December 2021. 
          As of January 2023, I am currently pursuing a masters in Cloud Computing Systems from UMGC.
        </p>
        
        <!-- Goals -->
        <p class="text-4xl my-10">Goals</p>
        <p class="text-lg mb-5">
          After working in the field and improving in skills as a software engineer, I noticed a change in my attitude towards working as one. 
          I wasn't passionate about work anymore because I could never do what I wanted or thought made sense in the context of working; it's always up to what the business/customers want. 
          Work doesn't feel fulfilling. It's not even a matter of burn-out because even after taking a hypothetical vacation/hiatus/sabbatical, I know I would return to the same feelings and attitude from before. 
        </p>
        <p class="text-lg mb-5">
          I want something of my own.
        </p>
        <p class="text-lg mb-5">
          It was around the beginning of the year 2022 that I decided that I want to start my own software company, trying to find ways to monetize the app ideas that I thought about over the months and years. 
          I didn't know where or how to start a business. I started to recall some of the courses I took from my associates in business and decided to start off with brain storming business ideas and core IP (intellectual property).
        </p>
        <p class="text-lg mb-5">
          I learned from my business associates, and just observing, that modern business models are revolving more around subscriptions services and PaaS (Platform as a Service). 
          People like autonomy and convenience so being able to make money on your own terms and with your own skills/assets sounds appealing to people. 
          Now was just trying to find a target market to serve.
        </p>
        <p class="text-lg mb-5">
          Like most people in modern times, I too order things online. 
          There have been times where I have ordered something from within the same state i'm that wasn't too horribly far from me, but because the item was moving through a commercial carrier especially on their own schedule, I had no choice to wait. 
          I remember thinking something along the lines of "What if there wa an app where I can request from someone to transport/take something from one place to another?". That is when I had the idea for <span class="font-bold">Carry</span>.
        </p>
        <p class="text-lg mb-5">
          This would be the defacto product/app for my software company goal. This was something I believe would benefit the public because it not only addresses a pain point but also addresses it in a way that benefit people who like to be self-imployed. 
          It's another means of an income for people while providing a conveniece for others. I started to get to work.
        </p>

        <!-- Startup -->
        <p class="text-4xl my-10">Startup</p>
        <p class="text-lg mb-5">
          From the day I had the idea for Carry, I started taking actionable steps.
        </p>
        <p class="text-lg mb-5">
          The very first thing I did was register a new business: <span class="font-bold">Ryan Myron Waite, LLC</span>. I also registered a DBA (doing business as): <span class="font-bold">Modern</span>. 
          Then opened a business bank account with the new business information. Found a logo to use via available online services/companies, got domain name for the website (carryapp.me), setup a Google business profile, and all other branding things.
        </p>
        <p class="text-lg mb-5">
          And next, the hard but fun part: writing the code and getting everything set up and working. Exactly how I want it. I started by building the backend, then the frontend, then the mobile and lastly setting up the architecture/infrastructure to deploy everything to.
        </p>
        <p class="text-lg mb-5">
          For the backend services, I was debating using the <a class="font-bold" target="_blank" href="https://microservices.io/">microservices architecture</a> (basically splitting every piece of functionality into its own app) 
          but realized I didn't need something so complicated so early; a monolith design can still be effect and easier to deal with starting out. The technologies used for the backend:
        </p>
        <ul>
          <li>JavaScript/TypeScript</li>
          <li>express.js</li>
          <li>PostgreSQL</li>
          <li>Sequelize</li>
          <li>AWS S3</li>
          <li>JSON Web Tokens</li>
          <li>Socket.IO</li>
          <li>Stripe</li>
        </ul>
        <p class="text-lg mb-5">
          I had the vision for how I wanted the app to work but doing things in the best way I knew how was complicated, so developing the backend took about a few months worth of time. Especially integrating the financial aspect; 
          Stripe made it as easy as possible to process financial transactions online.
        </p>
        <p class="text-lg mb-5">
          For the frontend, I know I was going to use Angular. Similar situation with the backend: I had the vision, but implementation and figuring out things from scratch is what took a bulk of the time and effort. 
          The frontend technologies uses:
        </p>
        <ul>
          <li>HTML, CSS, JavaScript/TypeScript</li>
          <li>Angular</li>
          <li>Fontawesome Icons</li>
          <li>Google Map Services (Maps, autocomplete address search, geocode, etc)</li>
          <li>Socket.IO</li>
          <li>Stripe Client SDK</li>
        </ul>
        <p class="text-lg mb-5">
          Just like the backend, developing the frontend took about a few months worth of time.
        </p>
        <p class="text-lg mb-5">
          Now, the mobile app...
        </p>
        <p class="text-lg mb-5">
          I knew I was going to have to use React Native and Expo (frameworks for making and deploying iOS and Android apps at the same time) to have the most reach and impact; 
          it just wasn't feasible to write the same application logic between Apple's Xcode and Google's Android Studio. I am lucky technologies like React Native and Expo exist. 
          I am not used to the frameworks but it was closely related to other tools I used so it wasn't the worst or hardest thing to catch on to. Developing the mobile app took the longest time.
        </p>
        <p class="text-lg mb-5">
          After developing and testing everything locally, I decided it was ready to be released to the public for official production use. Now it was setting up the infrastructure; 
          Amazon's AWS was the obvious choice for a few things but other platform providers are used too.
        </p>
        <p class="text-lg mb-5">
          The services I needed from AWS were sending emails (AWS SES), storing uploaded content (AWS S3) and serving a frontend website/webapp (AWS CloudFront). I decided to use Heroku to deploy the backend 
          since the UI and deployment model is far more intuitive, more cost effective and overall easier to deal with (I am sure they use AWS under the abstractions anyway).
        </p>
        <p class="text-lg mb-5">
          So, from the time of having the idea to now having everything setup and available, It's been a year. 
          The app may not win any comestic awards for how it looks but if Craigslist can get still serve its purposes with its basic UI, so can Carry (starting out). 
          Most importantly, the app works and serves a purpose: provides a conveniece and helps people make money, and i'm excited to call it my own. 
          It's the first, and perhaps only, app under my dream software company and I hope it will one day have the impact on others I envisioned it to have.
        </p>

        <p class="text-2xl mt-16 mb-5">
          Thanks for reading!
        </p>

        

  
      </section>
    </div>
  </div>
</div>