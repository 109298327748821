<div class="container mx-auto">
  <div class="background-white m-3 sm:m-16">
    <section class="p-3 sm:p-16 border border-gray-300 shadow-sm">
      
      <div class="font-sans">
        <p class="text-5xl mb-10">App Video Demo</p>

        <p class="text-xl mb-5">
          This is an end-to-end demonstration on how to use the app (no voice added). 
          It shows the sign-up process, creating a Stripe account, adding a payment method, subscribing, creating a deliery listing as a customer, fulfilling the delivery listing as a service provider/carrier and collecting and viewing payouts. 
          A few things to note:
        </p>
        <ul class="mb-7">
          <li>The platform uses Stripe; all of your finance and identity information is safe with and managed by Stripe, not Carry.</li>
          <li>When creating your Stripe account, Carry is checking if you added a payout account and that your identity is verified.</li>
          <li>When at the review screen for finishing the Stripe onboard, it is important to make sure that the required personal information is added. You may need to try a few times to get the verified status.</li>
        </ul>

        <div class="polaroid">
          <video controls src="https://static.carryapp.me/assets/demos/app-demo-2.mp4" class="w-full"></video>
        </div>
      </div>

    </section>
  </div>
</div>