<div class="font-sans">
  <section id="intro-section" class="p-8 sm:p-12 home-intro-section text-center">
    <div class="container gutter mx-auto">
      <h1 class="home-intro-title">Modern</h1>
      <p class="home-intro-text">The platform that uplifts and empowers you to infinity.</p>
      <img class="mx-auto" [src]="'./assets/img/modern/logo-original-200.png'" alt="Modern Logo">
    </div>
  </section>

  <section id="portfolio-section" class="portfolio home-section py-20 px-4">
    <img class="mx-auto w-64" [src]="'./assets/img/carry/app_icon.png'" alt="Carry Logo">
    <p class="text-2xl sm:text-5xl text-center mt-7 mb-24">Carry: Community Carrier</p>
    

    <p class="text-xl text-center font-bold mb-16">Download now!</p>
    <p class="text-xl text-center font-bold mb-16">
      Read the <a [routerLink]="['/about']">About</a> page to learn more about the platform!<br/>
      After downloading, watch the <a [routerLink]="['/demo']">Demo Video</a> and follow the <a [routerLink]="['/tutorials']">Tutorials</a> to learn how to use the app and get started!<br/>
      Other helpful links can be found in the bottom footer.
    </p>
    <div class="flex flex-col sm:flex-row gap-4 justify-center">
      <a href="https://apps.apple.com/app/carry-community-carrier/id6449396799" title="Download on Apple App Store" target="_blank">
        <img class="mx-auto w-48 sm:w-64" [src]="'./assets/img/app-store-download-transparent.png'" alt="App Store Download">
      </a>

      <a href="https://play.google.com/store/apps/details?id=com.ryanmyronwaite.carry" title="Download on Google Play Store" target="_blank">
        <img class="mx-auto w-48 sm:w-64" [src]="'./assets/img/play-store-download-transparent.png'" alt="Play Store Download">
      </a>
    </div>
  </section>
</div>