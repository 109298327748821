<div class="container mx-auto">
  <div class="background-white m-3 sm:m-16">
    <section class="p-3 sm:p-16 border border-gray-300 shadow-sm">
      
      <p class="text-5xl mb-5">Installing the App</p>
      <p class="text-xl mb-10">
        The app is available as a web app, and also on both Apple App Store and Google Play Store.
      </p>

      <div class="flex flex-col sm:flex-row gap-4">
        <a href="https://apps.apple.com/app/carry-community-carrier/id6449396799" title="Download on Apple App Store" target="_blank">
          <img class="mx-auto w-48 sm:w-64" [src]="'./assets/img/app-store-download-transparent.png'" alt="App Store Download">
        </a>
  
        <a href="https://play.google.com/store/apps/details?id=com.ryanmyronwaite.carry" title="Download on Google Play Store" target="_blank">
          <img class="mx-auto w-48 sm:w-64" [src]="'./assets/img/play-store-download-transparent.png'" alt="Play Store Download">
        </a>
      </div>

    </section>
  </div>
</div>