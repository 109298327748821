export enum DELIVERME_EVENT_TYPES {
  CARRIER_ASSIGNED = 'CARRIER_ASSIGNED',
  CARRIER_UNASSIGNED = 'CARRIER_UNASSIGNED',
  CARRIER_MARKED_AS_PICKED_UP = 'CARRIER_MARKED_AS_PICKED_UP',
  CARRIER_MARKED_AS_DROPPED_OFF = 'CARRIER_MARKED_AS_DROPPED_OFF',
  DELIVERY_NEW_TRACKING_UPDATE = 'DELIVERY_NEW_TRACKING_UPDATE',
  DELIVERY_NEW_MESSAGE = 'DELIVERY_NEW_MESSAGE',
  DELIVERY_ADD_COMPLETED_PICTURE = 'DELIVERY_ADD_COMPLETED_PICTURE',

  DELIVERY_FROM_PERSON_ID_PICTURE_ADDED = 'DELIVERY_FROM_PERSON_ID_PICTURE_ADDED',
  DELIVERY_FROM_PERSON_SIG_PICTURE_ADDED = 'DELIVERY_FROM_PERSON_SIG_PICTURE_ADDED',
  DELIVERY_TO_PERSON_ID_PICTURE_ADDED = 'DELIVERY_TO_PERSON_ID_PICTURE_ADDED',
  DELIVERY_TO_PERSON_SIG_PICTURE_ADDED = 'DELIVERY_TO_PERSON_SIG_PICTURE_ADDED',
  
  DELIVERY_COMPLETED = 'DELIVERY_COMPLETED',
  DELIVERY_RETURNED = 'DELIVERY_RETURNED',
  CARRIER_LOCATION_REQUESTED = 'CARRIER_LOCATION_REQUESTED',
  CARRIER_LOCATION_REQUEST_CANCELED = 'CARRIER_LOCATION_REQUEST_CANCELED',
  CARRIER_LOCATION_REQUEST_ACCEPTED = 'CARRIER_LOCATION_REQUEST_ACCEPTED',
  CARRIER_LOCATION_REQUEST_DECLINED = 'CARRIER_LOCATION_REQUEST_DECLINED',
  CARRIER_LOCATION_SHARED = 'CARRIER_LOCATION_SHARED',
  CARRIER_LOCATION_UNSHARED = 'CARRIER_LOCATION_UNSHARED',
  CARRIER_LOCATION_UPDATED = 'CARRIER_LOCATION_UPDATED',

  NEW_DELIVERME_MESSAGE = 'NEW_DELIVERME_MESSAGE',
  NEW_DELIVERME_MESSAGING = 'NEW_DELIVERME_MESSAGING',
  DELIVERME_MESSAGING_EVENTS_SUBSCRIBED = 'DELIVERME_MESSAGING_EVENTS_SUBSCRIBED',
  DELIVERME_MESSAGING_EVENTS_UNSUBSCRIBED = 'DELIVERME_MESSAGING_EVENTS',
  TO_DELIVERME_MESSAGING_ROOM = 'TO_DELIVERME_MESSAGING_ROOM',

  JOIN_TO_DELIVERME_MESSAGING_ROOM = 'JOIN_TO_DELIVERME_MESSAGING_ROOM',
  LEAVE_TO_DELIVERME_MESSAGING_ROOM = 'LEAVE_TO_DELIVERME_MESSAGING_ROOM',
  DELIVERME_MESSAGE_TYPING = 'DELIVERME_MESSAGE_TYPING',
  DELIVERME_MESSAGE_TYPING_STOPPED = 'DELIVERME_MESSAGE_TYPING_STOPPED',

  TO_DELIVERY = 'TO_DELIVERY',
}

export enum DELIVERME_NOTIFICATION_TARGET_TYPES {
  DELIVERY = 'DELIVERY',
  DELIVERY_TRACKING_UPDATE = 'DELIVERY_TRACKING_UPDATE',
}

export enum DeliveryCardDisplayMode {
  DEFAULT = `DEFAULT`,
  BROWSE = `BROWSE`,
}