import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'deliverme-delivery-container-page',
  templateUrl: './delivery-container-page.component.html',
  styleUrls: ['./delivery-container-page.component.scss']
})
export class DeliverMeDeliveryContainerPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
