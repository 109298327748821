<div class="container mx-auto" id="delivery-browse-recent-container">
  <div class="background-white m-3 sm:m-16">
    <section class="p-3 sm:p-16 border border-gray-300 shadow-sm">
      
      <div *ngIf="!deliveries.length" class="">
        <div class="uk-card-body">
          <p class="text-3xl mb-3">None</p>
        </div>
      </div>
    
      <div class="">
        <div *ngFor="let delivery of deliveries" class="margin-bottom-20">
          <deliverme-delivery-card
            [delivery]="delivery"
            [deliveryCardDisplayMode]="DeliveryCardDisplayMode.BROWSE"
            [showEmbeddedContent]="true"
            [you]="you"
          >
            <div class="delivery-card-content-container">
              <!-- Take this delivery? -->
              <button
                type="submit"
                class="btn-primary-1"
                [disabled]="loading"
                (click)="assignDelivery(delivery)"
              >
                Take Delivery!
              </button>
            </div>
          </deliverme-delivery-card>
        </div>
      </div>
      
      <button
        *ngIf="!end_reached"
        type="submit"
        class="btn-primary-1"
        [disabled]="loading"
        (click)="getDeliveries()"
      >
        Load More
      </button>

    </section>
  </div>
</div>