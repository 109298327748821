<div class="container mx-auto">
  <div class="background-white m-3 sm:m-16">
    <section class="p-3 sm:p-16 border border-gray-300 shadow-sm">
      
      <p class="text-5xl mb-10">View Stripe Connected Account Profile</p>
      <p class="text-xl mb-5">
        As a service provider using Stripe Connect, you are able to review all of the payouts you received from Carry.
      </p>

      <p class="text-xl mb-5">
        While logged in, tap the home button on the bottom navigation.
      </p>
      <img class="w-full sm:w-96 polaroid mb-5" [src]="'https://static.carryapp.me/assets/previews/iphone/home-screen-stripe-account-button.png'">

      <p class="text-xl mb-5">
        Tap the "Stripe Account Login" button. This will take you to your Stripe connected account with the platform.
      </p>
      <div class="flex flex-col sm:flex-row flex-wrap gap-3">
        <img class="w-full sm:w-96 polaroid mb-5" [src]="'https://static.carryapp.me/assets/previews/iphone/stripe-account-login-verification-phone-code.png'">
        <img class="w-full sm:w-96 polaroid mb-5" [src]="'https://static.carryapp.me/assets/previews/iphone/stripe-account-earnings-1.png'">
        <img class="w-full sm:w-96 polaroid mb-5" [src]="'https://static.carryapp.me/assets/previews/iphone/stripe-account-earnings-2.png'">
      </div>
      


    </section>
  </div>
</div>