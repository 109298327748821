<div>
  <div class="card-box-1">
    <div class="">
      <h3 class="text-3xl mb-3">Stats</h3>

      <p>Deliveries Created: {{ stats?.deliveries_count | number }}</p>
      <p>Delivering Completed: {{ stats?.delivering_completed_count | number }}</p>
      <p>Delivering In-Progress: {{ stats?.delivering_inprogress_count | number }}</p>
    </div>
  </div>
</div>